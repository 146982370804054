import { escape } from "./escape.js";
import { escapeRegExp } from "./escapeRegExp.js";
import { nbsp } from "./unicodes/nbsp.js";
import { wordjoiner } from "./unicodes/wordjoiner.js";

const formatStartSymbol = "[[";
const formatEndSymbol = "]]";

const formatTextReplacements = {
	nl: `<br />$1`,
	cp: `<span class="text-fstphlox">$1</span>`,
	cg: `<span class="text-fstgreen">$1</span>`,
	co: `<span class="text-fstonyx">$1</span>`,
	cw: `<span class="text-white">$1</span>`,
	wm: `<span class="font-medium">$1</span>`,
	sp: `<span class="font-medium text-fstonyx">$1</span>`,
	[`\\[(.*?)\\]`]: `<a class="link" href="$1">$2</a>`,
};

const escapedFormatStartSymbol = escapeRegExp(formatStartSymbol);
const escapedFormatEndSymbol = escapeRegExp(formatEndSymbol);

export function formatify(input: string | undefined): string {
	let result = input ?? "";
	result = result.normalize("NFC");
	result = result.replace(
		new RegExp(
			`(?<=(?:^|[^\\p{General_Category=Letter}])[\\p{General_Category=Letter}|&](?:${escapeRegExp(formatEndSymbol)})?) +|\\s(?=\\d|%)`,
			"gu",
		),
		nbsp,
	);
	result = result.replace(
		/(?<=\p{General_Category=Letter})-(?=\p{General_Category=Letter})(?![^[]*\]\[\[)/gu,
		`${wordjoiner}-${wordjoiner}`,
	);
	result = escape(result);

	for (const [start, replacement] of Object.entries(formatTextReplacements)) {
		result = result.replace(
			new RegExp(`${start}${escapedFormatStartSymbol}(.*?)${escapedFormatEndSymbol}`, "gu"),
			replacement,
		);
	}

	return result;
}
