export enum LinkVariant {
	Underline = "Underline",
	NoStyle = "NoStyle",
	Disabled = "Disabled",
	EventsNone = "EventsNone",
}

export function getLinkClass(variant: LinkVariant = LinkVariant.NoStyle): string {
	switch (variant) {
		case LinkVariant.Underline:
			return "link";
		case LinkVariant.NoStyle:
			return "";
		case LinkVariant.Disabled:
			return "cursor-not-allowed";
		case LinkVariant.EventsNone:
			return "pointer-events-none";
	}
}
