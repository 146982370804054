<script lang="ts">
	import facebook from "@core/assets/icons/facebook.svg?raw";
	import instagram from "@core/assets/icons/instagram.svg?raw";
	import linkedin from "@core/assets/icons/linkedin.svg?raw";
	import { socialLinks } from "../data/fastestCompanyInfo.js";
	import Icon from "./common/Icon.svelte";
	import Link from "./common/Link.svelte";
</script>

<div class="flex items-center gap-4 mt-3">
	<Link class="group" aria-label="LinkedIn" href={socialLinks.linkedin}>
		<Icon class="text-fstgrey-600 w-6 group-hover:text-fstgreen transition-colors" square icon={linkedin} />
	</Link>
	<Link class="group" aria-label="Facebook" href={socialLinks.facebook}>
		<Icon class="text-fstgrey-600 w-6 group-hover:text-fstgreen transition-colors" square icon={facebook} />
	</Link>
	<Link class="group" aria-label="Instagram" href={socialLinks.instagram}>
		<Icon class="text-fstgrey-600 w-6 group-hover:text-fstgreen transition-colors" square icon={instagram} />
	</Link>
</div>
