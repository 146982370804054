<script lang="ts">
	import CopyrightFooter from "@components/CopyrightFooter.svelte";
	import Socials from "@components/Socials.svelte";
	import Icon from "@components/common/Icon.svelte";
	import Link from "@components/common/Link.svelte";
	import Media from "@components/common/Media.svelte";
	import envelope from "@core/assets/icons/envelope.svg?raw";
	import headphones from "@core/assets/icons/headphones.svg?raw";
	import fastestLogoVertical from "@core/assets/images/fastest-logo-vertical-for-dark-background.svg";
	import { getAboutPath } from "@core/schema/paths/getAboutPath.js";
	import { getCareerPath } from "@core/schema/paths/getCareerPath.js";
	import { getClientsPath } from "@core/schema/paths/getClientsPath.js";
	import { getContactPath } from "@core/schema/paths/getContactPath.js";
	import { getDevelopmentPath } from "@core/schema/paths/getDevelopmentPath.js";
	import { getIndustriesPath } from "@core/schema/paths/getIndustriesPath.js";
	import { getPrivacyPolicyPath } from "@core/schema/paths/getPrivacyPolicyPath.js";
	import { getSolutionsPath } from "@core/schema/paths/getSolutionsPath.js";
	import { getVisualIdentityPath } from "@core/schema/paths/getVisualIdentityPath.js";
	import { getWebAppsPath } from "@core/schema/paths/getWebAppsPath.js";
	import { getGlobalTemplateProps } from "../../contexts/getGlobalTemplateProps.js";
	import { invoice, office } from "../../data/fastestCompanyInfo.js";

	const { tenant } = getGlobalTemplateProps();
</script>

<footer class="bg-fstonyx text-fstlight-300 pt-14 md:pt-28 overflow-x-hidden">
	<div class="grid-container grow">
		<div class="flex flex-col lg:flex-row gap-8 lg:gap-40 items-center justify-center">
			<Media
				class="mb-2"
				source={{ src: fastestLogoVertical, type: "image/svg+xml" }}
				width={245}
				height={194}
				alt="Fastest Solution logo"
			></Media>

			<div class="flex flex-col gap-4 lg:gap-6">
				<h3
					class="font-loos-normal font-medium tracking-[0.5em] uppercase text-fstgrey-600 text-xs text-center lg:text-left"
				>
					Máte rychlý dotaz?
				</h3>
				<p class="text-fstlight-300 text-sm mb-0 max-lg:max-w-xs text-center lg:text-left leading-normal">
					Napište na e-mail, nebo se s námi spojte telefonicky.
				</p>
				<div class="flex flex-col gap-2">
					<div class="flex gap-2 lg:gap-4 items-center justify-center lg:justify-normal">
						<Icon class="w-10 lg:w-12" square icon={envelope}></Icon>
						<Link
							href="mailto:info@fastest.cz"
							class="text-lg lg:text-2xl text-fstgreen leading-normal font-loos-wide font-black no-underline"
						>
							{office.email}
						</Link>
					</div>
					<div class="flex gap-2 lg:gap-4 items-center justify-center lg:justify-normal">
						<Icon class="w-10 lg:w-12" square icon={headphones}></Icon>
						<Link
							href="tel:{office.phone}"
							class="text-lg lg:text-2xl text-fstgreen leading-normal font-loos-wide font-black no-underline"
						>
							{office.phone}
						</Link>
					</div>
				</div>
			</div>
		</div>

		<div class="grid grid-cols-2 !px-4 lg:flex lg:flex-row gap-10 lg:gap-6 xl:gap-10 justify-center mt-12 md:mt-24">
			<div class="flex flex-col gap-4 lg:gap-6 lg:min-w-[11.375rem]">
				<h3 class="font-loos-normal font-medium tracking-[0.5em] uppercase text-fstgrey-600 text-xs">Office</h3>
				<address class="text-fstlight-300 text-sm not-italic">
					{office.street}<br />
					{office.desc}<br />
					{office.city}
				</address>
				<div class="hidden lg:block">
					<Socials />
				</div>
			</div>
			<div class="flex flex-col gap-4 lg:gap-6 lg:min-w-[11.375rem]">
				<h3 class="font-loos-normal font-medium tracking-[0.5em] uppercase text-fstgrey-600 text-xs">Sídlo</h3>
				<address class="text-fstlight-300 text-sm not-italic">
					{invoice.companyName}<br />
					{invoice.street}<br />
					{invoice.city}
				</address>
				<div>
					<p class="mb-0 text-fstlight-300 text-sm">IČO {invoice.ic}</p>
					<p class="mb-0 text-fstlight-300 text-sm">DIČ {invoice.dic}</p>
				</div>
			</div>
			<div class="flex flex-col gap-4 lg:gap-6 lg:min-w-[11.375rem]">
				<h3 class="font-loos-normal font-medium tracking-[0.5em] uppercase text-fstgrey-600 text-xs">Naše služby</h3>
				<Link href={getIndustriesPath(tenant)} class="text-fstlight-300 text-sm hover:text-fstgreen transition-colors"
					>Odvětví</Link
				>
				<Link href={getSolutionsPath(tenant)} class="text-fstlight-300 text-sm hover:text-fstgreen transition-colors"
					>Naše řešení</Link
				>
				<Link href={getDevelopmentPath(tenant)} class="text-fstlight-300 text-sm hover:text-fstgreen transition-colors"
					>Vývoj na míru</Link
				>
				<Link href={getWebAppsPath(tenant)} class="text-fstlight-300 text-sm hover:text-fstgreen transition-colors"
					>Webové aplikace</Link
				>
				<div class="block lg:hidden mt-10">
					<Socials />
				</div>
			</div>
			<div class="flex flex-col gap-4 lg:gap-6 lg:min-w-[11.375rem]">
				<h3 class="font-loos-normal font-medium tracking-[0.5em] uppercase text-fstgrey-600 text-xs">Společnost</h3>
				<div class="flex flex-col lg:flex-row gap-16">
					<div class="flex flex-col gap-4 lg:gap-6">
						<Link href={getClientsPath(tenant)} class="text-fstlight-300 text-sm hover:text-fstgreen transition-colors"
							>Klienti</Link
						>
						<Link href={getAboutPath(tenant)} class="text-fstlight-300 text-sm hover:text-fstgreen transition-colors"
							>O nás</Link
						>
						<Link href={getCareerPath(tenant)} class="text-fstlight-300 text-sm hover:text-fstgreen transition-colors"
							>Kariéra</Link
						>
						<Link href={getContactPath(tenant)} class="text-fstlight-300 text-sm hover:text-fstgreen transition-colors"
							>Kontakt</Link
						>
					</div>
					<div class="flex flex-col gap-4 lg:gap-6">
						<!--
						<Link href="#todo" class="text-fstlight-300 text-sm hover:text-fstgreen transition-colors">
							Všeobecné obchodní podmínky
						</Link>
						<Link href="#todo" class="text-fstlight-300 text-sm hover:text-fstgreen transition-colors">
							Zásady zpracování obchodních údajů
						</Link>
						<Link href="#todo" class="text-fstlight-300 text-sm hover:text-fstgreen transition-colors">
							Poučení o souborech cookies
						</Link>
						-->
						<Link
							href={getPrivacyPolicyPath(tenant)}
							class="text-fstlight-300 text-sm hover:text-fstgreen transition-colors"
						>
							Zásady zpracování osobních údajů
						</Link>
						<Link
							href={getVisualIdentityPath(tenant)}
							class="text-fstlight-300 text-sm hover:text-fstgreen transition-colors"
						>
							Vizuální identita
						</Link>
					</div>
				</div>
			</div>
		</div>
	</div>

	<CopyrightFooter />
</footer>
