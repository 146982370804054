<script lang="ts">
	import Link from "@components/common/Link.svelte";
	import { LinkVariant } from "@components/common/getLinkClass.js";
	import type { Breadcrumb } from "@core/schema/Breadcrumb.js";

	const { breadcrumbs }: { breadcrumbs: Breadcrumb[] } = $props();
</script>

{#if breadcrumbs.length > 0}
	<nav class="container-10 pb-8 pt-2 absolute hidden md:block">
		<ul class="flex gap-2">
			{#each breadcrumbs as { label, href }, index (index)}
				<li>
					<Link
						class="text-sm underline-offset-2"
						variant={index + 1 === breadcrumbs.length ? LinkVariant.EventsNone : LinkVariant.Underline}
						{href}
					>
						{label}
					</Link>
				</li>
				{#if index < breadcrumbs.length - 1}
					<li class="text-fstlight-400">–</li>
				{/if}
			{/each}
		</ul>
	</nav>
{/if}
