import { Locale, type LocalizedString } from "../Locale.js";
import type { Tenant } from "../Tenant.js";
import { getPathInLocale } from "./getPathInLocale.js";

export function getConnectWithFastestPath(tenant: Tenant): string {
	const pathMap: LocalizedString = {
		[Locale.cs]: "/chci-fastest",
		[Locale.en]: "/connect-with-fastest",
	};

	return getPathInLocale(tenant.locale, pathMap);
}
