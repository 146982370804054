<script lang="ts">
	import { Locale } from "@core/schema/Locale.js";
	import { getGlobalSettings } from "../../contexts/getGlobalSettings.js";
	import { getGlobalTemplateProps } from "../../contexts/getGlobalTemplateProps.js";

	const { COOKIES_CS, COOKIES_EN } = getGlobalSettings();
	const { tenant } = getGlobalTemplateProps();
	const COOKIES = {
		[Locale.cs]: COOKIES_CS,
		[Locale.en]: COOKIES_EN,
	}[tenant.locale];

	/* eslint-disable svelte/no-at-html-tags */
</script>

<svelte:head>
	<link rel="preconnect" href="https://cdn.cookie-script.com" />
</svelte:head>

{@html `<script type="text/javascript" charset="UTF-8" src="https://cdn.cookie-script.com/s/${COOKIES}.js" fetchpriority="low"></script>`}
