<script lang="ts" module>
	interface PreloadFont {
		type: string;
		href: string;
	}
</script>

<script lang="ts">
	import defaultOgImage from "@assets/images/fastest-og-share-image-1200x630px.jpg";
	import OrganisationStructuredData from "@components/OrganisationStructuredData.svelte";
	import ProductStructuredData from "@components/ProductStructuredData.svelte";
	import { createTitle } from "@utils/createTitle.js";
	import type { Snippet } from "svelte";
	import resolveConfig from "tailwindcss/resolveConfig.js";
	import LoosCompressedBold from "@core/assets/fonts/Loos/loos-compressed-bold.woff2";
	import LoosNormalRegular from "@core/assets/fonts/Loos/loos-normal-regular.woff2";
	import LoosWideBlack from "@core/assets/fonts/Loos/loos-wide-black.woff2";
	import LoosWideBold from "@core/assets/fonts/Loos/loos-wide-bold.woff2";
	import { tenants } from "@core/config/tenants.js";
	import { appName } from "@core/schema/names/appName.js";
	import tailwindConfig from "../../../tailwind.config.js";
	import "../../assets/css/main.css";
	import { getGlobalSettings } from "../../contexts/getGlobalSettings.js";
	import { getGlobalTemplateProps } from "../../contexts/getGlobalTemplateProps.js";
	import { lockScroll } from "../../state/lockScroll.svelte.js";
	import Breadcrumbs from "./Breadcrumbs.svelte";
	import Cookies from "./Cookies.svelte";
	import Footer from "./Footer.svelte";
	import GoogleAnalytics from "./GoogleAnalytics.svelte";
	import HeadFavicons from "./HeadFavicons.svelte";
	import Header from "./Header.svelte";
	import Leady from "./Leady.svelte";

	const {
		ogImage = defaultOgImage,
		children,
		withStructuredData,
	}: {
		ogImage?: string;
		children: Snippet;
		withStructuredData?: boolean;
	} = $props();

	const { ROBOTS, WEB_URL } = getGlobalSettings();
	const { breadcrumbs, pagePath, title, description, keywords, menu, index, tenant } = getGlobalTemplateProps();

	const pageTitle = createTitle(title);

	const fontsToPreload: PreloadFont[] = [
		{ href: LoosWideBlack, type: "font/woff2" },
		{ href: LoosWideBold, type: "font/woff2" },
		{ href: LoosNormalRegular, type: "font/woff2" },
		{ href: LoosCompressedBold, type: "font/woff2" },
	];
	const canonical = tenant.locale in pagePath ? `${WEB_URL}${pagePath[tenant.locale]}` : undefined;
	const themeColor = resolveConfig(tailwindConfig).theme.colors.white;

	$effect(() => {
		document.documentElement.style.overflow = lockScroll.lock ? "hidden" : "auto";
	});
</script>

<svelte:head>
	<!-- Preloads of fonts used at the top of pages -->
	{#each fontsToPreload as { href, type } (href)}
		<link rel="preload" as="font" {href} {type} crossorigin="anonymous" />
	{/each}

	<!-- Theme color -->
	<meta name="msapplication-TileColor" content={themeColor} />
	<meta name="theme-color" content={themeColor} />

	<!-- Site name -->
	<meta property="og:site_name" content={appName} />
	<meta name="twitter:site" content={appName} />
	<meta name="application-name" content={appName} />
	<meta name="apple-mobile-web-app-title" content={appName} />

	<!-- Dynamic Open graph tags (Meta) -->
	{#if canonical}
		<meta property="og:url" content={canonical} />
	{/if}
	<meta property="og:image" content={ogImage} />

	<!-- Dynamic Twitter Meta tags -->
	<meta name="twitter:card" content="summary_large_image" />
	<meta property="twitter:domain" content={WEB_URL} />
	{#if canonical}
		<meta property="twitter:url" content={canonical} />
	{/if}
	<meta name="twitter:image" content={ogImage} />

	<!-- Title, description and keywords -->
	<title>{pageTitle}</title>
	<meta property="title" content={pageTitle} />
	<meta property="og:title" content={pageTitle} />
	<meta name="twitter:title" content={pageTitle} />
	<meta name="description" content={description} />
	<meta name="og:description" content={description} />
	<meta name="twitter:description" content={description} />
	{#if keywords}
		<meta name="keywords" content={keywords} />
	{/if}

	<!-- Robots -->
	<meta name="robots" content={ROBOTS === "index" && index ? "index, follow" : "noindex, nofollow"} />

	<!-- Canonical and hreflang -->
	{#if canonical}
		<link rel="canonical" href={canonical} />
	{/if}
	{#each Object.values(tenants) as tenant (tenant.locale)}
		{#if tenant.locale in pagePath}
			<link rel="alternate" hreflang={tenant.locale} href="{WEB_URL}{pagePath[tenant.locale]}" />
		{/if}
	{/each}
</svelte:head>

<OrganisationStructuredData />

{#if withStructuredData}
	<ProductStructuredData />
{/if}

<HeadFavicons />

<GoogleAnalytics />

<Leady />

<div class="contents">
	<Header {menu} />

	<main class="grid-container grow relative">
		<Breadcrumbs {breadcrumbs} />

		{@render children()}
	</main>

	<Footer />
</div>

<Cookies />
