<script lang="ts">
	import fastestSnail from "@core/assets/icons/snail-grey.svg";
	import fastestLogoGrey from "@core/assets/images/fastest-logo-grey.svg";
	import Media from "./common/Media.svelte";
</script>

<div class="grid-container grow">
	<div class="flex mt-16 md:mt-36 -mb-[1px] justify-end items-end">
		<Media
			class="mr-64 text-fstgrey-800 hidden xl:inline-block"
			source={{ src: fastestSnail, type: "image/svg+xml" }}
			width={35}
			height={24}
			alt="Fastest Solution snail"
		></Media>
		<Media
			class="mr-52 text-fstgrey-800 hidden xl:inline-block"
			source={{ src: fastestSnail, type: "image/svg+xml" }}
			width={35}
			height={24}
			alt="Fastest Solution snail"
		></Media>
		<Media
			class="mr-48 text-fstgrey-800 hidden lg:inline-block"
			source={{ src: fastestSnail, type: "image/svg+xml" }}
			width={35}
			height={24}
			alt="Fastest Solution snail"
		></Media>
		<Media
			class="mr-40 text-fstgrey-800 hidden md:inline-block"
			source={{ src: fastestSnail, type: "image/svg+xml" }}
			width={35}
			height={24}
			alt="Fastest Solution snail"
		></Media>
		<Media
			class="mr-32 text-fstgrey-800 hidden sm:inline-block"
			source={{ src: fastestSnail, type: "image/svg+xml" }}
			width={35}
			height={24}
			alt="Fastest Solution snail"
		></Media>
		<Media
			class="mr-24 text-fstgrey-800"
			source={{ src: fastestSnail, type: "image/svg+xml" }}
			width={35}
			height={24}
			alt="Fastest Solution snail"
		></Media>
		<Media
			class="mr-20 text-fstgrey-800"
			source={{ src: fastestSnail, type: "image/svg+xml" }}
			width={35}
			height={24}
			alt="Fastest Solution snail"
		></Media>
		<Media
			source={{ src: fastestLogoGrey, type: "image/svg+xml" }}
			width={168}
			height={24}
			class="w-[10.5rem]"
			alt="Fastest Solution logo"
		></Media>
	</div>
</div>

<div class="bg-fstgrey-900 py-5">
	<p class="text-center text-sm leading-normal mb-0 mt-2 text-fstgrey-300">
		&copy; Fastest Solution s.r.o. – www.fastest.cz
	</p>
</div>
