import { Locale, type LocalizedString } from "../Locale.js";
import type { Tenant } from "../Tenant.js";
import { getPathInLocale } from "./getPathInLocale.js";

export function getPrivacyPolicyPath(tenant: Tenant): string {
	const pathMap: LocalizedString = {
		[Locale.cs]: "/gdpr",
		[Locale.en]: "/gdpr",
	};

	return getPathInLocale(tenant.locale, pathMap);
}
