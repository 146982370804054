import { Locale, type LocalizedString } from "../Locale.js";
import type { Tenant } from "../Tenant.js";
import { getPathInLocale } from "./getPathInLocale.js";

export function getSolutionsPath(tenant: Tenant): string {
	const pathMap: LocalizedString = {
		[Locale.cs]: "/reseni",
		[Locale.en]: "/solutions",
	};

	return getPathInLocale(tenant.locale, pathMap);
}
