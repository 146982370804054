<script lang="ts">
	import Button from "@components/common/Button.svelte";
	import Icon from "@components/common/Icon.svelte";
	import Link from "@components/common/Link.svelte";
	import { ButtonVariant } from "@components/common/getButtonClass.js";
	import Menu from "@components/layout/Menu.svelte";
	import closeIcon from "@core/assets/icons/close.svg?raw";
	import menuIcon from "@core/assets/icons/menu.svg?raw";
	import logo from "@core/assets/images/fastest-logo-header.svg?raw";
	import type { MenuItem } from "@core/schema/MenuItem.js";
	import { getConnectWithFastestPath } from "@core/schema/paths/getConnectWithFastestPath.js";
	import { getHomepagePath } from "@core/schema/paths/getHomepagePath.js";
	import { getGlobalTemplateProps } from "../../contexts/getGlobalTemplateProps.js";
	import { lockScroll } from "../../state/lockScroll.svelte.js";

	const { menu }: { menu: MenuItem[] } = $props();
	const { tenant } = getGlobalTemplateProps();

	let headerHeight = $state(0);
	let mobileMenuOpen = $state(false);
</script>

<header
	class={[
		"grid-container z-50 sticky top-0 left-0 right-0 transition-colors duration-300 bg-white border-b-2 border-fstlight-100",
	]}
	bind:clientHeight={headerHeight}
>
	<div
		class="flex flex-col lg:flex-row justify-start container-center lg:full-width xl:container-center lg:justify-between lg:!px-4"
	>
		<div class="flex items-center justify-between py-2 px-1 lg:px-0 lg:py-4">
			<Link aria-label="Logo" href={getHomepagePath(tenant)}>
				<Icon
					class={["mb-2 w-[8.75rem] h-5 lg:w-[10.5rem] lg:h-6 transition-colors duration-300 text-fstonyx"]}
					icon={logo}
				/>
			</Link>
			<div class="lg:hidden flex gap-1.5">
				<Link
					href={getConnectWithFastestPath(tenant)}
					variant={ButtonVariant.Hamburger}
					class="duration-300 transition-all leading-none px-4 py-2.5 text-xs"
				>
					Konzultace
				</Link>
				<Button
					variant={ButtonVariant.Hamburger}
					aria-label="Menu"
					class="duration-300 transition-all"
					onclick={(): void => {
						mobileMenuOpen = !mobileMenuOpen;
						lockScroll.lock = mobileMenuOpen;
					}}
				>
					<Icon class="w-6" square icon={mobileMenuOpen ? closeIcon : menuIcon} />
				</Button>
			</div>
		</div>

		<Menu bind:mobileOpen={mobileMenuOpen} items={menu} {headerHeight} />
	</div>
</header>
