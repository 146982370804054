import { Locale, type LocalizedString } from "../Locale.js";
import type { Tenant } from "../Tenant.js";
import { getPathInLocale } from "./getPathInLocale.js";

export function getAboutPath(tenant: Tenant): string {
	const pathMap: LocalizedString = {
		[Locale.cs]: "/o-nas",
		[Locale.en]: "/about-us",
	};

	return getPathInLocale(tenant.locale, pathMap);
}
