<script lang="ts">
	import { getGlobalSettings } from "../../contexts/getGlobalSettings.js";

	const { LEADY_TRACK_KEY } = getGlobalSettings();
	/* eslint-disable */
</script>

<svelte:head>
	{@html `
<script type="text/javascript">
var leady_track_key='${LEADY_TRACK_KEY}';
(function(){
    var l=document.createElement("script");l.type="text/javascript";l.async=true;
    l.src='https://ct.leady.com/'+leady_track_key+"/L.js";
    var s=document.getElementsByTagName("script")[0];s.parentNode.insertBefore(l,s);
  })();
</script>
`}
</svelte:head>
