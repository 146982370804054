<script lang="ts">
	import type { Snippet } from "svelte";
	import type { ClassValue } from "svelte/elements";
	import { Background } from "./Background.js";

	const {
		headline,
		desc,
		children,
		cta,
		gridHeadline,
		gridDesc,
		gridChildren,
		background,
		noPadding,
		paddingFollowNext,
		centered,
		id,
		decorativeHeadline,
		class: classname,
		hero,
	}: {
		headline?: Snippet;
		desc?: Snippet;
		children?: Snippet;
		cta?: Snippet;
		gridHeadline?: ClassValue;
		gridDesc?: ClassValue;
		gridChildren?: ClassValue;
		paddingFollowNext?: boolean;
		noPadding?: "bot" | "top" | "both";
		background?: Background;
		centered?: boolean;
		id?: string;
		decorativeHeadline?: boolean;
		class?: ClassValue;
		hero?: boolean;
	} = $props();

	const classHeroWrapperGrid = "lg:pt-[6.625rem] lg:pb-20 py-16 md:pt-20 md:pb-20";
	const classDefaultWrapperGrid = "py-16 md:pt-20 md:pb-24 lg:pt-24 lg:pb-28";

	const classNoPaddingBot = "!pb-0 !md:pb-0 !lg:pb-0";
	const classNoPaddingTop = "!pt-0 !md:pt-0 !lg:pt-0";

	const classCentered = "mx-auto text-center";

	const classPaddingFollowNext = "!pb-12 !md:pb-16 !lg:pb-20";
	const classDefaultInnerGrid = "container-start-1 container-end-12 lg:container-12";
	const onlyHeadline = !desc && !children;
	const onlyHeadlineAndChildren = !desc && children;

	const headlineClasses = {
		"text-fstonyx": background === Background.Light || background === undefined,
		[classCentered]: centered,
		"mb-8 lg:mb-14": onlyHeadline,
		"mb-4": !onlyHeadline,
		"mb-14": onlyHeadlineAndChildren,
		"uppercase font-loos-normal font-medium text-sm md:text-pmd tracking-[0.5em] mx-auto text-center lg:!mb-8":
			decorativeHeadline,
	};
</script>

<section
	class={{
		[background ?? Background.Light]: true,
		"text-white": background === Background.Onyx || background === Background.Phlox,
		"grid-container full-width": true,
		[classDefaultWrapperGrid]: !hero,
		[classHeroWrapperGrid]: hero,
		[classNoPaddingBot]: noPadding === "bot" || noPadding === "both",
		[classNoPaddingTop]: noPadding === "top" || noPadding === "both",
		[classPaddingFollowNext]: paddingFollowNext,
		classname,
	}}
	{id}
>
	{#if headline}
		<div class={[gridHeadline ?? classDefaultInnerGrid]}>
			{#if hero}
				<h1 class={headlineClasses}>
					{@render headline()}
				</h1>
			{:else}
				<h2 class={headlineClasses}>
					{@render headline()}
				</h2>
			{/if}
		</div>
	{/if}
	{#if desc}
		<div class={[gridDesc ?? classDefaultInnerGrid, centered && classCentered, "mt-2"]}>
			<div>
				{@render desc()}
			</div>
		</div>
	{/if}
	{#if children}
		<div class={[gridChildren ?? classDefaultInnerGrid, centered && classCentered]}>
			<div>
				{@render children()}
			</div>
		</div>
	{/if}
	{#if cta}
		{@render cta()}
	{/if}
</section>
