import { Locale, type LocalizedString } from "../Locale.js";
import type { Tenant } from "../Tenant.js";
import { getPathInLocale } from "./getPathInLocale.js";

export function getCareerPath(tenant: Tenant): string {
	const pathMap: LocalizedString = {
		[Locale.cs]: "/kariera",
		[Locale.en]: "/career",
	};

	return getPathInLocale(tenant.locale, pathMap);
}
