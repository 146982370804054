<script lang="ts" module>
	import type { Media } from "@core/schema/Media.js";

	type Sourceset = string | Media | [number, number] | [number, number, Media];
</script>

<script lang="ts">
	import type { MediaSrc } from "@components/common/MediaSrc.js";
	import type { SourceSetQuery } from "@utils/SourceSetQuery.js";
	import { parseSourceSet } from "@utils/parseSourceSet.js";
	import { sortSourceSetQuery } from "@utils/sortSourceSetQuery.js";
	import type { ClassValue } from "svelte/elements";
	import { UploadedFileBucket } from "@core/schema/UploadedFileBucket.js";
	import { getUploadedFilePath } from "@core/utils/getUploadedFilePath.js";
	import { lazyLoadVideo } from "@core/utils/lazyLoadVideo.js";
	import { thumb } from "@core/utils/thumb.js";
	import { getGlobalSettings } from "../../contexts/getGlobalSettings.js";
	import Lightbox from "./Lightbox.svelte";

	// eslint-disable-next-line svelte/no-unused-props
	const all: {
		width: number;
		height: number;
		alt: string;
		eager?: boolean;
		autoplay?: boolean;
		loop?: boolean;
		muted?: boolean;
		doesntPlayInline?: boolean;
		noControls?: boolean;
		class?: ClassValue;
		wrapperClass?: ClassValue;
		poster?: Media | string;
		sourcesets?: Partial<Record<SourceSetQuery, Sourceset>>;
		lightbox?: boolean | string;
		lightboxWidth?: number;
		source: MediaSrc;
	} = $props();

	const {
		class: customClass,
		wrapperClass,
		eager = false,
		autoplay,
		height,
		width,
		alt,
		loop = false,
		muted = false,
		doesntPlayInline = true,
		noControls = false,
		sourcesets = {},
		lightbox,
		lightboxWidth = 1920,
	} = $derived(all);

	const { STORAGE_URL, UPLOAD_URL } = getGlobalSettings();

	const bucketUrlMap: Record<UploadedFileBucket, string> = {
		[UploadedFileBucket.Storage]: STORAGE_URL,
		[UploadedFileBucket.Upload]: UPLOAD_URL,
	};

	const sortedSourceset = $derived(
		Object.entries(sourcesets).toSorted(([firstQuery], [secondQuery]) =>
			sortSourceSetQuery(firstQuery as SourceSetQuery, secondQuery as SourceSetQuery),
		) as [SourceSetQuery, [number, number] | [number, number, Media]][],
	);

	/* eslint-disable @typescript-eslint/no-confusing-void-expression */
</script>

{#snippet sourceset(query: SourceSetQuery, set: Sourceset, type: string, parentMedia: Media | null)}
	{#if typeof set === "string"}
		{#if type.startsWith("video")}
			<source srcset="{set}#t=0.001" {type} media={parseSourceSet(query)} />
		{:else}
			<source srcset={set} {type} media={parseSourceSet(query)} />
		{/if}
	{:else if "length" in set}
		{@const [height, width, media] = set}
		{#if media !== undefined && typeof media === "object"}
			{#if media.type.startsWith("image")}
				<source
					srcset={thumb(bucketUrlMap[media.bucket], width, height, media)}
					type="image/webp"
					media={parseSourceSet(query)}
				/>
			{:else}
				<source srcset={getUploadedFilePath(media, bucketUrlMap)} type={media.type} media={parseSourceSet(query)} />
			{/if}
		{:else if parentMedia?.type.startsWith("image")}
			<source
				srcset={thumb(bucketUrlMap[parentMedia.bucket], width, height, parentMedia)}
				type="image/webp"
				media={parseSourceSet(query)}
			/>
		{/if}
	{:else}
		<source srcset={getUploadedFilePath(set, bucketUrlMap)} type={set.type} media={parseSourceSet(query)} />
	{/if}
{/snippet}

{#snippet video(src: string, type: string, media: Media | null)}
	{@const poster =
		all.poster !== undefined
			? typeof all.poster === "string"
				? all.poster
				: thumb(bucketUrlMap[all.poster.bucket], 1920, 0, all.poster)
			: undefined}

	<video
		use:lazyLoadVideo={{ enabled: !eager, autoplay }}
		autoplay={autoplay && eager}
		{height}
		{width}
		{loop}
		{muted}
		playsinline={!doesntPlayInline}
		controls={!noControls}
		class={[customClass, wrapperClass]}
		{poster}
		preload={eager ? undefined : "none"}
	>
		{#each sortedSourceset as [query, set] (query)}
			{@render sourceset(query, set, type, media)}
		{/each}

		<source src="{src}#t=0.001" {type} />
	</video>
{/snippet}

{#snippet picture(src: string, type: string, media: Media | null)}
	<picture class={wrapperClass}>
		{#each sortedSourceset as [query, set] (query)}
			{@render sourceset(query, set, type, media)}
		{/each}

		<img class={customClass} {alt} {src} {width} {height} loading={eager ? "eager" : "lazy"} />
	</picture>
{/snippet}

{#snippet root()}
	{#if "src" in all.source}
		{#if all.source.type === "video"}
			{@render video(all.source.src, all.source.type, null)}
		{:else if all.source.type}
			{@render picture(all.source.src, all.source.type, null)}
		{/if}
	{:else if all.source.type.startsWith("video")}
		{@render video(getUploadedFilePath(all.source, bucketUrlMap), all.source.type, all.source)}
	{:else}
		{@render picture(getUploadedFilePath(all.source, bucketUrlMap), all.source.type, all.source)}
	{/if}
{/snippet}

{#if lightbox}
	{@const id = typeof lightbox === "string" ? lightbox : undefined}
	{@const caption = alt}

	{#if "src" in all.source}
		<Lightbox
			source={{ href: all.source.src }}
			{id}
			{caption}
			type={all.source.type.startsWith("video") ? "video" : "image"}
		>
			{@render root()}
		</Lightbox>
	{:else}
		<Lightbox
			source={{ media: all.source, width: lightboxWidth }}
			{id}
			{caption}
			type={all.source.type.startsWith("video") ? "video" : "image"}
		>
			{@render root()}
		</Lightbox>
	{/if}
{:else}
	{@render root()}
{/if}
