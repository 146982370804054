<script lang="ts">
	import { Locale } from "@core/schema/Locale.js";
	import { getGlobalSettings } from "../../contexts/getGlobalSettings.js";
	import { getGlobalTemplateProps } from "../../contexts/getGlobalTemplateProps.js";

	const { GOOGLE_ANALYTICS_CS, GOOGLE_ANALYTICS_EN } = getGlobalSettings();
	const { tenant } = getGlobalTemplateProps();
	const GOOGLE_ANALYTICS = {
		[Locale.cs]: GOOGLE_ANALYTICS_CS,
		[Locale.en]: GOOGLE_ANALYTICS_EN,
	}[tenant.locale];

	/* eslint-disable */
</script>

<svelte:head>
	{@html `<script async src="https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS}"></script>`}
	{@html `
	<script>
		window.dataLayer ||= [];
		function gtag(){window.dataLayer.push(arguments);}
		gtag('js', new Date());
		gtag('config', '${GOOGLE_ANALYTICS}');
	</script>
	`}
</svelte:head>
