export const invoice = {
	companyName: "Fastest Solution s.r.o.",
	street: "Radlická 3201/14",
	city: "150 00 Praha",
	ic: "28591232",
	dic: "CZ28591232",
	email: "ucetni@fastest.cz",
	bankAccount: "230298084/0300",
	databox: "2jg5w8i",
};

export const office = {
	street: "Pobialova 819/14",
	desc: "3. a 4. patro",
	city: "702 00 Ostrava",
	email: "info@fastest.cz",
	phone: "+420 773 050 550",
};

export const socialLinks = {
	facebook: "https://www.facebook.com/fastest.solution",
	instagram: "https://www.instagram.com/fastestsolutioncz/",
	linkedin: "https://www.linkedin.com/company/fastestsolution/",
};
