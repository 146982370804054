export enum Locale {
	cs = "cs",
	en = "en",
}

export type LocalizedString = Record<Locale, string>;

export type LocalizedStringArray = Record<Locale, string[]>;

export type LocalizedBoolean = Record<Locale, boolean>;

export type LocalizedID = Record<Locale, string>;

export type Localized<Type> = Record<Locale, Type>;
