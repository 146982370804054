<script lang="ts">
	import type { ClassValue } from "svelte/elements";

	const { icon, class: iconClass, square = false }: { icon: string; class?: ClassValue; square?: boolean } = $props();

	/* eslint-disable svelte/no-at-html-tags */
</script>

<!-- Setting min and max width/height because Safari 16 is a bad boy and refuses to work properly when setting width/height -->
<div
	class={[
		iconClass,
		"shrink-0 [&>svg]:max-h-full [&>svg]:min-h-full [&>svg]:min-w-full [&>svg]:max-w-full flex justify-center items-center",
		{
			"aspect-square": square,
		},
	]}
>
	{@html icon}
</div>
