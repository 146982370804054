import type { Config } from "tailwindcss";
import defaultTheme from "tailwindcss/defaultTheme.js";

export default {
	darkMode: "class",
	theme: {
		extend: {
			fontFamily: {
				sans: ["loos-normal", ...defaultTheme.fontFamily.sans],
				"loos-normal": ["loos-normal"],
				"loos-wide": ["loos-wide"],
				"loos-compressed": ["loos-compressed"],
			},
			fontSize: {
				"2xs": "0.625rem",
				xs: ["0.75rem", "2"],
				sm: ["0.9375rem", "1.6"], // small text
				base: ["1.0625rem", "1.29"], // default text
				"menu-item": ["1.9375rem", "1.03"], // menu item
				p: ["1.0625rem", "1.64"], // p
				pmd: ["1.25rem", "1.5"], // pmd
				md: ["1.25rem", "1.1"], // btn
				"2md": ["1.375rem", "1.1"], // btn
				lg: ["1.75rem", "1.15"], // cards heading
				"2lg": ["2rem", "1.15"], //
				xl: ["2.5rem", "1.15"], // footer claim
				"2xl": ["3rem", "1.15"], // h1
				"3xl": ["3.5rem", "1.15"],
			},
			colors: {
				fstgreen: {
					DEFAULT: "#44D62C",
					animation: "#40F223",
					shadow: "#44D62C33",
				},
				fstphlox: {
					DEFAULT: "#E132E1",
					animation: "#FF10FF",
					shadow: "#FF18FF",
				},
				fstlight: {
					100: "#F4F5F5",
					200: "#EFEEEE",
					300: "#DBDADA",
				},
				fstgrey: {
					200: "#8C8D8D",
					300: "#7F8080",
					400: "#727373",
					500: "#656666",
					600: "#595858",
					700: "#4C4B4B",
					800: "#3F3E3E",
					900: "#323131",
				},
				fstonyx: {
					DEFAULT: "#212020",
					blur: "#141414",
				},
				fstivory: {
					DEFAULT: "#fff",
				},
			},
		},
	},
} satisfies Partial<Config>;
