import type { GlobalSettings } from "./GlobalSettings.js";
import type { GlobalTemplateProps } from "./GlobalTemplateProps.js";
import { globalSettingsContextKey } from "./globalSettingsContextKey.js";
import { globalTemplatePropsContextKey } from "./globalTemplatePropsContextKey.js";

export function createContext(props: GlobalTemplateProps, globalSettings: GlobalSettings): Map<string, unknown> {
	const context = new Map<string, unknown>();
	context.set(globalTemplatePropsContextKey, props);
	context.set(globalSettingsContextKey, globalSettings);
	return context;
}
