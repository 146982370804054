<script lang="ts">
	import Link from "@components/common/Link.svelte";
	import { isMenuActive } from "@utils/isMenuActive.js";
	import type { HTMLAnchorAttributes } from "svelte/elements";
	import type { MenuItem } from "@core/schema/MenuItem.js";
	import { getGlobalTemplateProps } from "../contexts/getGlobalTemplateProps.js";

	const { pagePath, tenant } = getGlobalTemplateProps();
	const currentPath = pagePath[tenant.locale];
	const { menuItem, ...rest }: { menuItem: MenuItem } & Omit<HTMLAnchorAttributes, "href"> = $props();
</script>

<Link
	{...rest}
	href={menuItem.href}
	class={[
		"lg:font-loos-normal text-fstonyx font-loos-wide duration-300 transition-all no-underline py-4 px-4 lg:px-0 block lg:py-0",
		rest.class,
		isMenuActive(menuItem, currentPath ?? "/") ? "text-fstphlox" : "text-fstonyx hover:text-fstgreen",
	]}
/>
