<script lang="ts">
	import AriaText from "@components/common/AriaText.svelte";
	import type { HTMLAnchorAttributes } from "svelte/elements";
	import { isEnum } from "@core/utils/enum/isEnum.js";
	import { ButtonVariant } from "./getButtonClass.js";
	import { getButtonClass } from "./getButtonClass.js";
	import { type LinkVariant, getLinkClass } from "./getLinkClass.js";

	const isButtonVariant = isEnum(ButtonVariant);
	const { variant, ...rest }: { variant?: ButtonVariant | LinkVariant } & HTMLAnchorAttributes = $props();
</script>

<a {...rest} class={[isButtonVariant(variant) ? getButtonClass(variant) : getLinkClass(variant), rest.class]}>
	<AriaText aria={rest["aria-label"] ?? ""}>
		{@render rest.children?.()}
	</AriaText>
</a>
