import type { MenuItem } from "@core/schema/MenuItem.js";

export function isMenuActive(menuItem: MenuItem, currentPath: string): boolean {
	if (currentPath === menuItem.href) {
		return true;
	}

	if (menuItem.submenuItems) {
		return menuItem.submenuItems.some((menuItem) => isMenuActive(menuItem, currentPath));
	}

	return false;
}
