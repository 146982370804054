export enum ButtonVariant {
	Green = "Green",
	GreenSmall = "GreenSmall",
	Phlox = "Phlox",
	GreenOutlineDarkBg = "GreenOutlineDarkBg",
	GreenOutlineLightBg = "GreenOutlineLightBg",
	GreenOutlineLightBgMedium = "GreenOutlineLightBgMedium",
	PhloxOutlineLightBg = "PhloxOutlineLightBg",
	GreenOutlineLightBgSmall = "GreenOutlineLightBgSmall",
	Hamburger = "Hamburger",
	HamburgerDark = "HamburgerDark",
}

// TODO sizes of buttons

export function getButtonClass(variant: ButtonVariant): string {
	const commonClassesForLargeButtons =
		"inline-block tracking-[0.01em] text-center text-base sm:text-md sm:px-8 px-6 rounded-full sm:py-4 py-3 max-w-[28.75rem] w-full font-medium";

	const commonClassesForMediumButtons =
		"inline-block tracking-[0.01em] w-fit text-center text-base sm:px-8 px-[2.625rem] rounded-full sm:py-2.5 py-[0.8125rem] max-w-[28.75rem] font-medium";

	const commonClassesForSmallButtons = "text-sm leading-[1] py-2 px-6 rounded-full w-fit font-medium";

	const commonClassesHamburger = "rounded-full p-1";

	switch (variant) {
		case ButtonVariant.Green:
			return `${commonClassesForLargeButtons} text-white bg-fstgreen btn-green-animation border-2 border-transparent`;
		case ButtonVariant.Phlox:
			return `${commonClassesForLargeButtons} text-white bg-fstphlox btn-phlox-animation border-2 border-transparent`;
		case ButtonVariant.GreenOutlineDarkBg:
			return `${commonClassesForLargeButtons} text-fstgreen border-fstgreen bg-fstgrey-900 border-2`;
		case ButtonVariant.PhloxOutlineLightBg:
			return `${commonClassesForLargeButtons} text-fstphlox border-fstphlox bg-fstivory border-2 btn-phloxoutline-animation hover:text-white hover:border-transparent`;
		case ButtonVariant.GreenOutlineLightBg:
			return `${commonClassesForLargeButtons} text-fstgreen border-fstgreen bg-fstivory border-2 btn-greenoutline-animation hover:text-white hover:border-transparent`;
		case ButtonVariant.GreenOutlineLightBgMedium:
			return `${commonClassesForMediumButtons} text-fstgreen border-fstgreen bg-fstivory border-2 btn-greenoutline-animation hover:text-white hover:border-transparent`;
		case ButtonVariant.GreenSmall:
			return `${commonClassesForSmallButtons} text-white bg-fstgreen btn-green-animation border-2 border-transparent`;
		case ButtonVariant.GreenOutlineLightBgSmall:
			return `${commonClassesForSmallButtons} text-fstgreen border-fstgreen bg-fstivory border-2 btn-greenoutline-animation hover:text-white hover:border-transparent`;
		case ButtonVariant.Hamburger:
			return `${commonClassesHamburger} bg-fstlight-100 text-fstonyx`;
		case ButtonVariant.HamburgerDark:
			return `${commonClassesHamburger} bg-fstgrey-900 text-fstgreen rounded-full p-1`;
	}
}
