import { tenants } from "../../config/tenants.js";
import type { Locale, LocalizedString } from "../Locale.js";

export function getPathInLocale(locale: Locale, path: string | Partial<LocalizedString>): string {
	if (typeof path === "object") {
		const pathOfLocale = path[locale];
		if (!pathOfLocale) {
			throw new Error(`Path does not contain ${locale} locale`);
		}
		path = pathOfLocale;
	}

	if (!path.startsWith("/")) {
		throw new Error("Path must start with a slash.");
	}

	return `${tenants[locale].prefix}${path}`;
}
