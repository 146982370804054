<script lang="ts">
	import type { HTMLButtonAttributes } from "svelte/elements";
	import { type ButtonVariant, getButtonClass } from "./getButtonClass.js";

	const { variant, ...rest }: { variant: ButtonVariant } & HTMLButtonAttributes = $props();
</script>

<button {...rest} class="{getButtonClass(variant)} {rest.class}">
	{@render rest.children?.()}
</button>
