import { Locale, type Localized } from "../schema/Locale.js";
import type { Tenant } from "../schema/Tenant.js";
import { isEnum } from "../utils/enum/isEnum.js";
import tenants from "./tenants.json" with { type: "json" };

const isLocale = isEnum(Locale);

function isTenant(obj: unknown): obj is Tenant {
	return obj !== null && typeof obj === "object" && "locale" in obj && isLocale(obj.locale);
}

const map: Partial<Localized<Tenant>> = {};
try {
	if (!Array.isArray(tenants)) {
		throw new Error("Not an array");
	}
	if (tenants.length !== Object.values(Locale).length) {
		throw new Error("Record count does not match");
	}
	for (const tenant of tenants) {
		if (!isTenant(tenant)) {
			throw new Error("Invalid record");
		}
		if (map[tenant.locale]) {
			throw new Error("Duplicate tenant ID found");
		}
		map[tenant.locale] = tenant;
	}
} catch (err) {
	throw new Error(`Tenants config invalid: ${String(err)}`);
}
const frozen = Object.freeze(map as Localized<Tenant>);
export { frozen as tenants };
