import kucera from "@assets/images/members/meet-kucera-martin.jpg?w=185&webp&imagetools";
import tyson from "@assets/images/members/meet-tyson-jakub.jpg?w=185&webp&imagetools";
import type { ContactPerson } from "@components/ContactPerson.js";
import type { Tenant } from "@core/schema/Tenant.js";

export enum BookMeetingPerson {
	Tyson = "Tyson",
	Kucera = "Kucera",
}

export function getBookMeetingPeople(tenant: Tenant): Record<BookMeetingPerson, ContactPerson> {
	return {
		[BookMeetingPerson.Tyson]: {
			name: "Jakub Tysoň",
			role: "Co-founder & Developer",
			phone: "+420 731 956 030",
			email: "tyson@fastest.cz",
			linkedin: "jakub-tyson",
			photo: tyson,
			show: true,
		},
		[BookMeetingPerson.Kucera]: {
			name: "Martin Kučera",
			role: "Manager & Developer",
			phone: "+420 773 050 550",
			email: "kucera@fastest.cz",
			linkedin: "kucera-martin",
			photo: kucera,
			show: true,
		},
	};
}
