import { Locale, type LocalizedString } from "../Locale.js";
import type { Tenant } from "../Tenant.js";
import { getPathInLocale } from "./getPathInLocale.js";

export function getVisualIdentityPath(tenant: Tenant): string {
	const pathMap: LocalizedString = {
		[Locale.cs]: "/vizualni-identita",
		[Locale.en]: "/visual-identity",
	};

	return getPathInLocale(tenant.locale, pathMap);
}
