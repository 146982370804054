<script lang="ts" module>
	declare global {
		interface Window {
			refreshFsLightbox?(): void;
		}
	}
</script>

<script lang="ts">
	import { type Snippet, onMount } from "svelte";
	import type { ClassValue } from "svelte/elements";
	import type { Media } from "@core/schema/Media.js";
	import { UploadedFileBucket } from "@core/schema/UploadedFileBucket.js";
	import { getUploadedFilePath } from "@core/utils/getUploadedFilePath.js";
	import { thumb } from "@core/utils/thumb.js";
	import { getGlobalSettings } from "../../contexts/getGlobalSettings.js";

	const all: {
		caption?: string;
		class?: ClassValue;
		id?: string;
		type: "image" | "video" | "youtube";
		children: Snippet;
		source: { href: string } | { media: Media; width: number };
	} = $props();

	async function refreshAndInitializeLightbox(): Promise<void> {
		// @ts-expect-error fslightbox does not provide types :)
		await import("@core/assets/js/fslightbox-pro-3.4.1/fslightbox.js");
		window.refreshFsLightbox?.();
	}

	onMount(() => {
		void refreshAndInitializeLightbox();
	});

	function update(_value: typeof all.source): void {
		if (typeof window !== "undefined") {
			void refreshAndInitializeLightbox();
		}
	}

	const { STORAGE_URL, UPLOAD_URL } = getGlobalSettings();

	const bucketUrlMap: Record<UploadedFileBucket, string> = {
		[UploadedFileBucket.Storage]: STORAGE_URL,
		[UploadedFileBucket.Upload]: UPLOAD_URL,
	};

	$effect(() => {
		update(all.source);
	});

	const href = $derived.by<string>(() => {
		if ("href" in all.source) {
			return all.source.href;
		} else {
			if (all.source.media.type.startsWith("video")) {
				return getUploadedFilePath(all.source.media, bucketUrlMap);
			} else {
				return thumb(bucketUrlMap[all.source.media.bucket], all.source.width, 0, all.source.media);
			}
		}
	});
</script>

<a {href} class={all.class} data-fslightbox={all.id ?? ""} data-type={all.type} data-caption={all.caption}>
	{@render all.children()}
</a>
