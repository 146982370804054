import { Locale, type LocalizedString } from "../Locale.js";
import type { Tenant } from "../Tenant.js";
import { getPathInLocale } from "./getPathInLocale.js";

export function getDevelopmentPath(tenant: Tenant): string {
	const pathMap: LocalizedString = {
		[Locale.cs]: "/vyvoj",
		[Locale.en]: "/development",
	};

	return getPathInLocale(tenant.locale, pathMap);
}
