<script lang="ts">
	import MenuItemLink from "@components/MenuItemLink.svelte";
	import Formatted from "@components/common/Formatted.svelte";
	import Icon from "@components/common/Icon.svelte";
	import Link from "@components/common/Link.svelte";
	import { ButtonVariant } from "@components/common/getButtonClass.js";
	import { isMenuActive } from "@utils/isMenuActive.js";
	import chevron from "@core/assets/icons/chevron.svg?raw";
	import type { MenuItem } from "@core/schema/MenuItem.js";
	import { getConnectWithFastestPath } from "@core/schema/paths/getConnectWithFastestPath.js";
	import { getGlobalTemplateProps } from "../../contexts/getGlobalTemplateProps.js";

	let {
		mobileOpen = $bindable(false),
		/* eslint-disable prefer-const */
		items,
		headerHeight,
		/* eslint-enable prefer-const */
	}: { mobileOpen?: boolean; items: MenuItem[]; headerHeight: number } = $props();

	const { pagePath, tenant } = getGlobalTemplateProps();
	const currentPath = pagePath[tenant.locale];

	const menuItemElements = $state<HTMLLIElement[]>([]);
	const submenuOpenMap = $state<Record<number, boolean>>({});

	function checkClickOutside(event: MouseEvent): void {
		const target = event.target as HTMLElement;
		for (let index = 0; index < items.length; index++) {
			const openSubmenu = submenuOpenMap[index] ?? false;
			const menuItemElement = menuItemElements[index];
			if (openSubmenu && menuItemElement && !menuItemElement.contains(target)) {
				submenuOpenMap[index] = false;
			}
		}
	}
</script>

<svelte:window onclick={checkClickOutside} />

<div class=" max-lg:fixed left-0 right-0 bottom-0 pointer-events-none" style:top="{headerHeight}px">
	<div
		class={[
			"flex overflow-auto lg:overflow-visible flex-col lg:flex-row lg:bg-transparent bg-white lg:gap-8 gap-5 pb-8 lg:pb-0 pt-8 pointer-events-auto lg:pt-0 transition-[transform,background-color] h-full duration-100",
		]}
		class:max-lg:pointer-events-none={!mobileOpen}
		class:max-lg:translate-x-full={!mobileOpen}
	>
		<ul class="flex flex-col lg:items-center lg:flex-row lg:gap-6">
			{#each items as menuItem, index (index)}
				{@const openSubmenu = submenuOpenMap[index] ?? false}
				<li
					bind:this={menuItemElements[index]}
					class={{
						"lg:h-full flex flex-col relative [&:not(:last-child)]:border-b [&:not(:last-child)]:lg:border-0 border-fstlight-100 transition-all": true,
					}}
					onpointerenter={(): void => {
						if (!window.matchMedia("(pointer: coarse)").matches) {
							submenuOpenMap[index] = true;
						}
					}}
					onpointerleave={(): void => {
						if (!window.matchMedia("(pointer: coarse)").matches) {
							submenuOpenMap[index] = false;
						}
					}}
				>
					<MenuItemLink
						class="lg:h-full flex text-right lg:text-left justify-end items-center gap-0.5 lg:text-sm lg:font-medium font-bold lg:text-nowrap text-2lg"
						{menuItem}
						onclick={(event: MouseEvent): void => {
							if (window.matchMedia("(pointer: coarse)").matches) {
								if (!openSubmenu && (menuItem.submenuItems?.length ?? 0) > 0) {
									event.preventDefault();
									submenuOpenMap[index] = !openSubmenu;
								}
							}
						}}
					>
						<span class="mt-0.5">
							<Formatted text={menuItem.name} />
						</span>
						{#if (menuItem.submenuItems?.length ?? 0) > 0}
							<button
								aria-label="Otevřít menu"
								class="shrink-0 lg:pointer-events-none"
								onclick={(event: MouseEvent): void => {
									if (window.matchMedia("(pointer: coarse)").matches) {
										event.preventDefault();
										event.stopPropagation();
										submenuOpenMap[index] = !openSubmenu;
									}
								}}
							>
								<Icon
									icon={chevron}
									class={[
										openSubmenu ? "rotate-[270deg]" : "rotate-90",
										"w-10 lg:w-auto transition-transform duration-300",
										isMenuActive(menuItem, currentPath ?? "/") ? "text-fstphlox" : "text-fstgreen",
									]}
									square
								/>
							</button>
						{/if}
					</MenuItemLink>

					{#if menuItem.submenuItems}
						<div
							class={{
								"grid transition-all overflow-clip duration-300": true,
								"grid-rows-[0fr]": !openSubmenu,
								"grid-rows-[1fr]": openSubmenu,
							}}
						>
							<div
								class={{
									"bg-white min-h-0 px-4 lg:px-[4.5rem] lg:font-medium lg:pb-14 lg:pt-12 lg:flex flex-col gap-8 lg:-left-[4.5rem] lg:origin-[4.5rem_0] lg:rounded-[1.25rem] lg:border-2 border-fstlight-100 lg:absolute lg:top-full transition-all": true,
									"lg:opacity-100 lg:pointer-events-auto lg:scale-100": openSubmenu,
									"lg:opacity-0 lg:pointer-events-none lg:scale-75": !openSubmenu,
								}}
							>
								<span class="uppercase text-xs tracking-[0.5em] text-fstlight-400 hidden lg:inline-block">
									{menuItem.name}
								</span>
								<ul
									class="grid grid-cols-1 w-full lg:w-max gap-x-14 gap-y-1 lg:gap-y-7"
									class:lg:grid-cols-2={menuItem.submenuItems.length > 1}
								>
									{#each menuItem.submenuItems as submenuItem (submenuItem)}
										<li class="text-fstonyx tracking-[0.015em]">
											<MenuItemLink menuItem={submenuItem} class="text-right lg:text-left text-2md lg:text-base">
												<Formatted text={submenuItem.name} />
											</MenuItemLink>
										</li>
									{/each}
								</ul>
							</div>
						</div>
					{/if}
				</li>
			{/each}
		</ul>
		<Link
			variant={ButtonVariant.GreenSmall}
			href={getConnectWithFastestPath(tenant)}
			class="hidden lg:block duration-100 transition-all lg:my-4 text-nowrap"
		>
			Chci fastest
		</Link>
		<div class="px-2 lg:hidden flex items-center lg:my-4">
			<Link
				variant={ButtonVariant.Phlox}
				href={getConnectWithFastestPath(tenant)}
				class={["mx-auto text-md text-nowrap"]}
			>
				Chci fastest
			</Link>
		</div>
	</div>
</div>
