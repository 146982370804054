<script lang="ts">
	import { getStructuredDataScript } from "@utils/getStructuredDataScript.js";
	import { getGlobalTemplateProps } from "../contexts/getGlobalTemplateProps.js";
	import { invoice, office } from "../data/fastestCompanyInfo.js";
	import { BookMeetingPerson, getBookMeetingPeople } from "../data/getBookMeetingPeople.js";

	const { tenant } = getGlobalTemplateProps();

	const contacts = getBookMeetingPeople(tenant);
	const script = getStructuredDataScript({
		"@context": "https://schema.org" as const,
		"@type": "Organization" as const,
		name: invoice.companyName,
		telephone: office.phone,
		url: "https://www.fastest.cz",
		logo: "https://www.fastest.cz/fastest-dark-corporate-vertical.jpg",
		address: {
			"@type": "PostalAddress",
			addressLocality: "Ostrava, Czechia",
			postalCode: "702 00",
			streetAddress: office.email,
		},
		email: office.email,
		member: [
			{
				"@type": "Person",
				name: contacts[BookMeetingPerson.Kucera].name,
				jobTitle: contacts[BookMeetingPerson.Kucera].role,
				worksFor: {
					"@type": "Organization",
					name: "Fastest Solution",
				},
				sameAs: `https://www.linkedin.com/in/${contacts[BookMeetingPerson.Kucera].linkedin}`,
			},
			{
				"@type": "Person",
				name: contacts[BookMeetingPerson.Tyson].name,
				jobTitle: contacts[BookMeetingPerson.Tyson].role,
				worksFor: {
					"@type": "Organization",
					name: "Fastest Solution",
				},
				sameAs: `https://www.linkedin.com/in/${contacts[BookMeetingPerson.Tyson].linkedin}`,
			},
		],
	});

	/* eslint-disable svelte/no-at-html-tags */
</script>

<svelte:head>
	{@html script}
</svelte:head>
