<script lang="ts">
	import { getStructuredDataScript } from "@utils/getStructuredDataScript.js";

	const script = getStructuredDataScript({
		"@context": "https://schema.org",
		"@type": "Product",
		name: "Fastest",
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "5",
			reviewCount: "21",
			bestRating: "5",
			worstRating: "5",
		},
	});

	/* eslint-disable svelte/no-at-html-tags */
</script>

<svelte:head>
	{@html script}
</svelte:head>
